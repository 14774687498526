<template>
    <section class="ebo-accordion" data-test="ebo-accordion">
        <header
          class="ebo-accordion__header ec-bg-light ec-container ec-mw-100 ec-rounded ec-p-3 ec-row ec-align-items-center ec-justify-content-between ec-mx-0"
          data-test="ebo-accordion__header"
          @click="onHeaderClick"
          @keypress="onHeaderClick"
        >
            <div class="ec-row ec-align-items-center ec-mx-0">
              <div
                class="ebo-accordion__header-icon ec-ico ec-ico-chevron-right"
                :class="{ 'ebo-accordion__header-icon-rotated': isOpen }"
              ></div>
              <slot name="header" />
            </div>
            <ToggleButton
              data-test="ebo-accordion__toggle-button"
              v-if="showToggle && !isReadonly"
              :show-status="true"
              :is-checked="isAccepted"
              @change="onToggleChange"
            />
            <div class="ec-text-small" data-test="ebo-accordion__readonly-text" v-else-if="showToggle && isReadonly">
              {{ $gettext('Always Enabled') }}
            </div>
        </header>
        <article
          class="ebo-accordion__content ec-bg-white ec-container ec-mt-2"
          :class="{'ebo-accordion__content-open': isOpen}"
        >
            <slot name="content" />
        </article>
    </section>
</template>

<script>
import '@/i18n/config';
import ToggleButton from './ToggleButton.vue';

export default {
  name: 'Accordion',
  components: {
    ToggleButton,
  },
  props: {
    showToggle: Boolean,
    isReadonly: Boolean,
    isAccepted: Boolean,
    isOpen: Boolean,
  },
  methods: {
    onHeaderClick() {
      this.$emit('toggle-accordion', !this.isOpen);
    },
    onToggleChange(value) {
      this.$emit('toggle-change', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.ebo-accordion {
  &__header {
    &-icon {
      &-rotated {
        transform: rotateZ(90deg);
        transition: transform 0.4s;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    max-height: 0px;
    overflow-y: hidden;
    transition: max-height 0.1s;

    &-open {
      max-height: 200vh;
      transition: max-height 0.4s;
    }
  }
}
</style>
