<template>
  <!-- eslint-disable vuejs-accessibility/form-control-has-label -->
  <div>
    <h3 class="ec-mb-3"><span>{{ $gettext('Forgot your password?') }}</span></h3>
    <div class="ec-mb-3">
      <span>{{ $gettext('Enter your email address to reset your password') }}</span>
    </div>

    <form @submit.prevent="checkForm" autocomplete="off">
      <!-- Email -->
      <div class="ec-form-group" :class="{ 'ec-is-invalid': !emailIsValid }">
        <input type="text" class="ec-form-control" id="email" name="email" v-model="email"
               ref="tooltip-email" data-toggle="tooltip" :data-original-title="emailTooltipMsg"
               data-custom-class="ec-tooltip--invalid" data-placement="right" data-container=".auth-wrapper"
               :placeholder="emailPlaceholder" @focus="cleanInputValidation('email')">
      </div>

      <!-- Buttons and links -->
      <div class="actions ec-d-flex ec-flex-column ec-align-items-center">
        <div class="btn__wrapper">
          <button type="submit" class="ec-btn ec-btn-primary btn-send" v-if="!loading">
            <span>{{ $gettext('Send') }}</span>
          </button>
          <div class="ec-loading" v-else></div>
        </div>
        <template v-if="showBackToLoginButton">
          <hr>
          <button type="button" class="ec-btn ec-btn-link btn-back-login" @click="backToLogin" :disabled="loading">
            <span class="ec-text-body">{{ $gettext('Back to login') }}</span>
          </button>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import LoginService from '../services/login-service';

const { validate } = window;
const $ = window.jQueryEC;

export default {
  name: 'ForgotPasswordForm',

  props: {
    showBackToLoginButton: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      email: null,
      emailIsValid: true,
      emailTooltipMsg: this.$gettext('Invalid email'),
      emailPlaceholder: this.$gettext('john@gmail.com'),
      loading: false,
    };
  },

  methods: {
    /**
     * Cleans any sign of wrong validation in an input.
     *
     * @param input {String} Input which will be cleaned
     */
    cleanInputValidation(input) {
      this[`${input}IsValid`] = true;
      $(this.$refs[`tooltip-${input}`]).tooltip('hide');
      $(this.$refs[`tooltip-${input}`]).tooltip('disable');
    },

    /**
     * Validates an input and actives the error tooltip if it is necessary.
     *
     * @param input {String} Input which will be validated
     * @return {Boolean} If the input is valid
     */
    validateInput(input) {
      let isValid = true;
      if (input === 'email') {
        this.emailTooltipMsg = this.validateEmail(this[input]);
        isValid = !this.emailTooltipMsg;
      }
      $(this.$refs[`tooltip-${input}`]).tooltip();
      $(this.$refs[`tooltip-${input}`]).tooltip(!isValid ? 'enable' : 'disable');
      this[`${input}IsValid`] = isValid;
      return isValid;
    },

    /**
     * Validates the email input.
     *
     * @param input {String} Input which will be validated
     * @return {String} The text is filled if there is some error's validation
     */
    validateEmail(input) {
      let text;
      if (!text && !this.validate('empty', input)) {
        text = this.$gettext('Email cannot be empty');
      }
      if (!text && !this.validate('email-@', input)) {
        text = this.$gettext('Email address must contain @');
      }
      if (!text && !this.validate('email-dot', input)) {
        text = this.$gettext('Email address must contain .');
      }
      if (!text && !this.validate('email', input)) {
        text = this.$gettext('Invalid email');
      }
      return text;
    },

    /**
     * Displays a notification by emitting a notification event to the shared bus.
     *
     * @param message {String} Message of the notification
     * @param type {String} Type of the notification
     */
    displayNotification: /* istanbul ignore next: TODO - not covered */ function displayNotification(message, type) {
      this.$bus.$emit('notification', {
        message,
        type,
      });
    },

    /**
     * Checks form.
     *
     * @return {Boolean} If the form is valid
     */
    checkForm() {
      const validEmail = this.validateInput('email');
      if (validEmail) {
        this.loading = true;
        /* istanbul ignore next: TODO - not covered */
        LoginService.forgotPasswordRequest(this.email)
          .then(() => {
            this.loading = false;
            this.email = null;
            this.$bus.$emit('forgot-pass-email-sent');
          })
          .catch(() => {
            this.loading = false;
            this.displayNotification(this.$gettext('Unfortunately there was a problem, please try again. If the problem persists, please contact us.'), 'error');
          });
      }
      return validEmail;
    },

    /**
     * Emits an event to display the login component.
     */
    backToLogin() {
      this.$bus.$emit('toggle-forgot-password');
    },

    /**
     * Invokes the `validate` function defined inside the `window` object.
     *
     * @param type {String} Type of validation
     * @param text {String} Text to validate
     */
    validate(type, text) {
      return validate(type, text);
    },
  },
};
</script>

<style lang="scss" scoped>
  .actions {
    .btn__wrapper {
      width: 100%;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0 1rem 0;

      .btn-send {
        max-width: 16rem;
        flex: 100% 1;
      }
    }

    .ec-btn.ec-btn-link .ec-text-body {
      color: inherit;
    }

    hr {
      width: 100%;
    }
  }
</style>
